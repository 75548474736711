import { digit2 } from "."
import { isValidDate } from "./validators"

export const hoursAgo = (hours: number): Date => {
  const then = new Date()
  then.setUTCHours(then.getUTCHours() - hours)
  return then
}

export const daysAgo = (days: number): Date => {
  const then = new Date()
  then.setUTCDate(then.getUTCDate() - days)
  return then
}

export const yearsAgo = (years: number): Date => {
  const then = new Date()
  then.setUTCFullYear(then.getUTCFullYear() - years)
  return then
}

export const daysSince = (d: Date): number => {
  return Math.floor((new Date().getTime() - d.getTime()) / (1000 * 60 * 60 * 24))
}

export const monthsSince = (d: Date): number => {
  return Math.floor(daysSince(d) / 30)
}

export const dateAndTime = (d: Date) => {
  const month = d.getMonth() + 1
  const day = digit2(d.getDate())
  const hours = d.getHours()
  const minutes = d.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  return `${(hours % 12) || 12}:${digit2(minutes)}${ampm} ${month}/${digit2(day)}`
}

export const yyyymmdd = (d: Date): string => {
  const year = d.getUTCFullYear().toString()
  const month = digit2(d.getUTCMonth() + 1)
  const day = digit2(d.getUTCDate())

  return `${year}-${month}-${day}`
}

export const mmddyyyy = (d: Date): string => {
  const year = d.getUTCFullYear().toString()
  const month = digit2(d.getUTCMonth() + 1)
  const day = digit2(d.getUTCDate())

  return `${month}/${day}/${year}`
}

export const isDaylightSavings = (d?: Date): boolean => {
  const _d = d || new Date()
  const jan = new Date(_d.getUTCFullYear(), 0, 1)
  const jul = new Date(_d.getUTCFullYear(), 6, 1)
  return jan.getTimezoneOffset() !== jul.getTimezoneOffset()
}

export const extractDate = (dt: string): Date | undefined => {
  let year, month, day
  let date: Date | undefined = undefined

  const _v = (dt as string).trim()
  let matches: RegExpMatchArray | null
  if (/^\d{4}-\d{2}-\d{2}$/.test(_v)) {
    // yyyy-mm-dd
    [year, month, day] = (_v).split('-').map(n => parseInt(n, 10))
  } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(_v)) {
    // val is ISO date format
    date = new Date(_v)
    // eslint-disable-next-line no-cond-assign
  } else if (matches = _v.match(/^\d{1,2}([-/])\d{1,2}[-/]\d{2,4}$/)) {
    // m/d/yy[yy] or m-d-yy[yy]
    // matches[1] is separator; String.match returns null if no matches
    [month, day, year] = _v.split(matches[1]).map(n => parseInt(n, 10))
  } else {
    console.warn('unknown date format', dt)
  }

  let str
  if (year) {
    if (year.toString().length <= 2) {
      const currentYear = new Date().getUTCFullYear() % 100
      year += year >= currentYear ? 1900 : 2000
    }
    // coerce to iso for usage with timezone, assume noon east coast time
    const offset = isDaylightSavings() ? 5 : 4
    str = `${year}-${digit2(month)}-${digit2(day)}T12:00:00.000-0${offset}:00`
    date = new Date(str)
  }

  return date
}

export const validateDate = (
  dateStr: string,
  compareFn: (date: Date) => boolean,
  errorMessage: string,
): string => {
  const formatError = isValidDate(dateStr)
  if (formatError !== '') {
    return formatError
  }

  const extractedDate = extractDate(dateStr)
  
  if (compareFn(extractedDate)) {
    return errorMessage
  }

  return ''
}
