import { Dictionary, LoanDocument, LoggedUpload, PreSubmitStatus, ProjectType, RequiredDocConfig } from './index'

export class StatusData {
  id: string
  lookup?: string
  tenantId?: string
  tenantName?: string
  address: string
  applicationReceived?: string
  nTPGiven?: string
  authorizedInstallers?: string[]
  dueDate?: string  // date of next deadline
  bg?: string
  customerName?: string
  businessName?: string
  description?: string
  deprecated?: boolean
  uploadType?: string
  installersStatus?: string
  owner?: string
  lastModified?: string
  lastStatusChange?: Date
  docsSubmittedText?: string
  uploads?: LoggedUpload[]
  installerDocs?: Dictionary<Dictionary<LoanDocument>>
  isClosed?: boolean
  hasUpload?: LoggedUpload
  createdDate?: Date
  progress?: number
  projectType?: ProjectType
  notesVisibleToInstallers?: string
  requiredDocuments?: RequiredDocConfig[] // from the step configuration
  requiredDocsCount?: number              // number that's actually outstanding for the installer
  approvedDocsCount?: number
  docsNeedSupportActionCount?: number
  docsNeedInstallerActionCount?: number

  email?: string
  phone?: string
  updatedDate?: Date
  presubmitStatus?: PreSubmitStatus
  loanAmount?: number

  // V1 new fields
  productId: string // this might be a reference to the upcoming Product model
  monthlyAverageEnergy: number
  paydownAmount: number
  batteryCost: number
  treeRemovalCost: number

  constructor(o: Partial<StatusData>) {
    Object.keys(o).forEach(k => this[k] = o[k])

    this.lastStatusChange = new Date(this.lastStatusChange)
    this.createdDate = new Date(this.createdDate)
    this.updatedDate = new Date(this.updatedDate)

    this.uploads = this.uploads?.map(u => {
      u.timestamp = new Date(u.timestamp)
      return u
    }) || []

    this.uploads.forEach(u => u.documents?.forEach(doc => {
      doc.history?.forEach(h => {
        h.timestamp = new Date(h.timestamp)
      })
    }))
  }

  uiSort(): void {
    this.uploads.forEach(u => u.documents?.forEach(doc => {
      doc.history?.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf())
    }))
    this.uploads.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf())
  }
}

